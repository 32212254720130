<script>
import countryISOmapping from '@utils/countryISOmapping'

export default {
    props: {
        competitor: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            imageError: false,
        }
    },
    computed: {
        countryCode() {
            return this.competitor.country ? countryISOmapping(this.competitor.country) : ''
        },
    },
}
</script>

<template>
    <div v-if="competitor" :class="$style.wrapper">
        <!-- Photo -->
        <div :class="$style.photoWrapper">
            <img
                v-if="competitor.photo && !imageError"
                :src="competitor.photo"
                :class="$style.photo"
                @error="imageError = true"
            />
        </div>
        <img
            :src="'https://flagcdn.com/56x42/' + countryCode.toLowerCase() + '.png'"
            onerror="this.style.display='none'"
            :class="$style.country"
        />
        <!-- Names -->
        <div :class="$style.names">
            <div :class="$style.riderName">
                {{ competitor.name }}
            </div>
            <div :class="$style.horseName">
                {{ competitor.horseName }}
            </div>
        </div>
        <!-- Start Number -->
        <div :class="$style.startNumber">
            {{ competitor.startNumber }}
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';
.country {
    margin-bottom: 1em;
    opacity: 0;
    transform: translateX(-100%);
    animation: names 1s ease forwards;
    animation-delay: 1.5s;
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// Photo
.photoWrapper {
    position: relative;
}
.photo {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    margin-bottom: 1em;
    border: 5px solid var(--color-link);
    border-radius: $border-radius-round;
    box-shadow: 0 0 0 5px var(--color-link);
    opacity: 0;
    transform: scale(0);
    animation: image 1.5s ease forwards;
}

@keyframes image {
    to {
        box-shadow: 0 0 0 150px var(--color-link-lighter);
        opacity: 1;
        transform: scale(1);
    }
}

// Names
.names {
    text-align: center;
}
.riderName {
    @include font-heading;

    font-size: 2em;
    opacity: 0;
    transform: translateX(-100%);
}
.horseName {
    margin-top: 3px;
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0;
    transform: translateX(100%);
}
.riderName,
.horseName {
    animation: names 1s ease forwards;
    animation-delay: 1.5s;
}

@keyframes names {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

// Start Number
.startNumber {
    @include font-heading;

    margin-top: 40px;
    font-size: 5em;
    opacity: 0;
    transform: translateY(-100%);
    animation: startNumber 500ms ease forwards;
    animation-delay: 2.5s;
}

@keyframes startNumber {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
